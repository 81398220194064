
export default {
	layout: 'auth',
  middleware: ['guest'],
  head() {
    return {
      title: `Login | ${this.$config.siteName}`,
			meta: [
				{ hid: 'title', name: 'title', content: `Login | ${this.$config.siteName}` },
				{ hid: 'description', name: 'description', content: `Login at WebToolsMate. The best free online web tools to make your life easier.` },
				// Facebook meta settings
				{ hid: 'og:site_name', property: 'og:site_name', content: `${this.$config.siteName}` },
				{ hid: 'og:title', property: 'og:title', content: `Login | ${this.$config.siteName}` },
				{ hid: 'og:description', property: 'og:description', content: `Login at WebToolsMate. The best free online web tools to make your life easier.` },
				{ hid: 'og:type', property: 'og:type', content: 'article' },
				{ hid: 'og:image', property: 'og:image', content: `https://webtoolsmate.com/storage/social/pages/login.png` },
				{ hid: 'og:url', property: 'og:url', content: `https://webtoolsmate.com/login` },
				{ hid: 'og:locale', property: 'og:locale', content: 'en_US' },
				// Twitter meta settings
				{ hid: 'twitter:site', name: 'twitter:description', content: '@webtoolsmate' },
				{ hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
				{ hid: 'twitter:url', name: 'twitter:url', content: `https://webtoolsmate.com/login` },
				{ hid: 'twitter:creator', name: 'twitter:creator', content: 'webtoolsmate' },
				{ hid: 'twitter:title', name: 'twitter:title', content: `Login | ${this.$config.siteName}` },
				{ hid: 'twitter:description', name: 'twitter:description', content: `Login at WebToolsMate. The best free online web tools to make your life easier.` },
				{ hid: 'twitter:image', name: 'twitter:image', content: `https://webtoolsmate.com/storage/social/pages/login.png` },
			]
    };
  },
  data() {
    return {
      form: this.$vform({
      	recaptcha: '',
        email: '',
        password: '',
        remember_me: false
      })
    };
  },
  methods: {
    socialLogin(service) {
       window.location.href = `${this.$config.apiURL}/auth/${service}`;
    },
    onError (error) {
    	this.form.errors.set('message', 'Please validate the captcha field before submitting your request!');
    },
		async login() {
		  try {
    		const token = await this.$recaptcha.getResponse();

    		this.form.recaptcha = token;

		    this.$auth.loginWith('local', {
          data: this.form
        }).then(res => {
          this.form.reset();
        })
        .catch(e => {
		  		if ( e.response ) { 
		  			this.form.errors.set(e.response.data.errors);
		  		}
        });

        await this.$recaptcha.reset();

		  } catch (e) {
		  		if ( e.response ) { 
		  			this.form.errors.set(e.response.data.errors);
		  		}
		  }
		}
    //
  }
};
